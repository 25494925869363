.flashes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .flash {
    display: inline-block;
    padding: 5px;
    border-width: 2px;
    border-style: solid;
    font-size: 14px;
    border-radius: 5px;
    font-weight: bold;

    &.error {
      background: #FFDDDD;
      color: red;
      border-color: red;
    }

    &.warn {
      background: #FFFFDD;
      color: #999977;
      border-color: #999977;
    }

    &.info {
      background: #DDFFDD;
      color: green;
      border-color: green;
    }
  }
}

a:link {
  color:           #0000FF;
  text-decoration: none;
}

a:active {
  color:           #0000FF;
  text-decoration: none;
}

a:visited {
  color:           #0000FF;
  text-decoration: none;
}
